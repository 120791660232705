<template>
  <div class="info-card">
    <!-- ยอดอ่าน -->
    <div class="card-child">
      <span>ยอดอ่าน</span>
      <h3><a-icon type="eye" /></h3>
      <span class="count">{{readCount | numFormat}}</span>
    </div>

    <!-- จำนวนตอน -->
    <!-- <div class="card-child">
      <span>จำนวนตอน</span>
      <h3><a-icon type="book" /></h3>
      <span class="count">{{chapterCount | numFormat}}</span>
    </div> -->

    <!-- จำนวนคอมเม็น -->
    <div class="card-child">
      <span>ความคิดเห็น</span>
      <h3><a-icon type="message" /></h3>
      <span class="count">{{commentCount | numFormat}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['readCount', 'commentCount', 'chapterCount']
}
</script>

<style scoped>
.info-card{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  gap: 2rem;
}
.card-child{
  text-align: center;
}
.count{
  font-weight: bold;
}
@media (min-width: 768px) { 
  .info-card{
    justify-content: flex-start;
  }
  .card-child{
    border: 2px #eeeeee solid;
    padding: 1rem;
    border-radius: 5px;
  }
}
</style>